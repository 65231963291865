import { defineAsyncComponent } from 'vue';
import { thereAreStudies } from '@/api/patients';
import Swal from 'sweetalert2';

export default ({
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '70%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			config: {
			},
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '70%'
			},
			config: {
				edit: {
					panel: {
						scrollable: true,
						width: '90%',
						height: '700px'
					}
				},
				sorting: true,
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {
						config: {
							sorting: 'patients.name'
						}
					},
					pol: {},
					dateofbirth: {},
					age: {},
					age_month: {},
					personal_number: {}
				}
			},
			component: defineAsyncComponent(() => import('./forms/Select.vue'))
		}
	},

	onBeforeDelete: async function (data: any) {
		const response: any = await thereAreStudies(data.id);

		if (response) {
			Swal.fire('Внимание', 'Для удаления данного пациента необходимо удалить все созданные на него осмотры', 'warning');

			return false;
		}

		return true;
	}
})